import React from 'react'
//import {useIntl} from "gatsby-plugin-intl"
import {  graphql } from 'gatsby'
import Layout from '../../components/layout'
import Header from '../../components/header'
import OneBlockTwoTextAlt from '../../components/oneBtwoTalt'



const ProjectPage = (props) => (
  <Layout>
    
    <Header 
      title='Ficotex'
      text='Projekte'
    />
    
    <div className='row'>
      <div className='col-12'>
      <p> .
      </p>
      </div>
    </div>

      <OneBlockTwoTextAlt
      image={props.data.imageOne}
      title='Bioökonmie '
      /*  */ 
      text=' 
      as Projekt "Bio-Ökonomie im Non-Food Sektor" entwickelt neue Materialien, die dazu beitragen sollen, dass Rohstoffressourcen 
      und die Umwelt geschont werden. Durch die Entwicklung von biobasierten Bausteinen für Materialien und 
      Chemikalien sowie weiterer biobasierter Produkte trägt das Projekt zu einer nachhaltigen Wirtschaft und zur CO2-Reduktion bei.'
      href='https://www.3-n.info/'
      
      />

      <OneBlockTwoTextAlt
      position= 'right'
      image={props.data.imageTwo}
      title='Selektives Lasersintern (SLS)'
      col='col-1'
      /* subtitle= 'K 300-4'  */ 
      text='Beim Selektives Lasersintern oder kurz Lasersintern wird mittels Laserstrahlen pulverförmiges Ausgangsmaterial verschmolzen. 
      Die Schichtdicke liegt bei ca 100 µm. 
      Wir setzen unterschiedliche Materialien wie PA oder TPE ein. Die maximale Drucgrösse beträgt 90 x 130 X 230 mm.
      '
      />
      <OneBlockTwoTextAlt
      image={props.data.imageThree}
      title='FDM'
      col='col-2'
      /*subtitle= 'K 300-4'  */ 
      text='Mit unseren FDM-Drucker können wir bis zu 4 unterschiedliche Materialen drucken. Dadurch können unterschiedliche Materialqualitäten 
      inklusive Stützmaterial in einem Arbeitsgang gedruckt werden'
      />
      <OneBlockTwoTextAlt
      position= 'right'
      image={props.data.imageFour}
      title='Finish  / raus'
      col='col-3'
      /*subtitle= 'K 300-4'  */ 
      text='Je nach Drucktechnik können die Objekte nach dem Druck weiter optimiert werden:
      Oberflächenveredlung, Schleifen, Fräsen oder auch Lackieren: Zur weiteren Optimierung sind vielfältige Verfahren möglich'
      />
  

    </Layout>
)


export default ProjectPage;

//fluidImage is defined on imagePage
export const pageQuery = graphql`
  query {

    imageOne: file(relativePath: { eq: "CAD-Zeichnungen-RK-1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "foto.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree: file(relativePath: { eq: "CAD-Zeichnungen-RK-1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageFour: file(relativePath: { eq: "foto.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

  }
`